import { settings } from "./settings";

export const entity_language = {
    entity: {
        user: 'Utenti',
        user_group: 'Gruppi',
        user_entity_saved_filters: 'Filtri salvati',
        user_entity_preferences: 'Preferenze entità',
        customer: 'Clienti',
        customer_contacts: 'Contatti clienti',
        supplier_contacts: 'Contatti fornitori',
        supplier: 'Fornitori',
        sales_agent: 'Segnalatori',
        customer_payment_condition: 'Condizioni di pagamento Cliente',
        supplier_payment_condition: 'Condizioni di pagamento Fornitore',
        vat_condition: 'Condizioni IVA',
        customer_offer_row: 'Riga preventivo cliente',
        customer_order_row: 'Riga ordine cliente',
        supplier_order_row: 'Riga ordine fornitore',
        item_model_category: 'Categorie',
        customer_order: 'Ordini cliente',
        gyv_customer_order: 'Ordini cliente',
        supplier_order: 'Ordini fornitore',
        gyv_supplier_order: 'Ordini fornitore',
        customer_offer: 'Preventivi',
        gyv_customer_offer: 'Preventivi',
        activity: 'Attività',
        delivery: 'Consegne',
        transport_document_reason: 'Causali DDT',
        item_model_movement: 'Storico movimentazioni',
        log: 'Log di sistema',
        category: 'Categorie',
        notification: 'Notifiche',
        customer_order_payment: 'Storico pagamenti',
        gyv_customer_order_rows_open: 'Righe aperte ordini cliente'
    },
    labels: {
        "user": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idUserGroup": 'Gruppo',
            "name": 'Nome',
            "surname": 'Cognome',
            "username": 'Username',
            "email": 'Email',
            "level": 'Livello utente',
            "level.options.0": 'Super Amministratore',
            "level.options.1": 'Amministratore',
            "level.options.2": 'Montatore',
            "level.options.3": 'Venditore',
            "avatar": 'Avatar',
            "password": 'Password',
            "percentage": 'Provvigione [%]'
        },
        "user_group": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome gruppo',
        },
        "user_entity_saved_filters": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "name": 'Nome',
            "notes": 'Note',
            "content": 'Json filtri'
        },
        "user_entity_preferences": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "content": 'Json preferenze'
        },
        "customer": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome cognome / Rag. Sociale',
            "code": 'Codice identificativo',
            "address": 'Indirizzo',
            "city": 'Città',
            "province": 'Provincia',
            "region": 'Regione',
            "postalCode": 'Codice postale',
            "latitude": 'Latitudine',
            "longitude": 'Longitude',
            "email": 'Email',
            "phone": 'Telefono',
            "vatNumber": 'P.iva',
            "taxCode": 'Codice fiscale',
            "paymentNotes": 'Note pagamento',
            "type": 'Tipo',
            "type.options.0_POTENTIAL": 'Potenziale',
            "type.options.10_CONVERTED": 'Convertito',
            "notes": 'Note',
            "idPaymentCondition": 'Condizione di pagamento',
            "vat": 'Condizione iva',
            "contacts": 'Contatti',
            "idVatCondition" : 'Condizione IVA'
        },
        "customer_contacts": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idCustomer": 'Cliente',
            "name": 'Nome e cognome',
            "role": 'Ruolo',
            "phone": 'Telefono',
            "email": 'Email',
            "notes": 'Note'
        },
        "supplier": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Ragione Sociale',
            "address": 'Indirizzo',
            "city": 'Città',
            "province": 'Provincia',
            "state": 'Nazione',
            "postalCode": 'Codice postale',
            "email": 'Email',
            "phone": 'Telefono',
            "vatNumber": 'P.iva',
            "taxCode": 'Codice fiscale',
            "commercial_discount": 'Sconto commerciale',
            "cash_flow_discount": 'Sconto cassa',
            "paymentNotes": 'Note pagamento',
            "notes": 'Note',
            "idPaymentCondition": 'Condizione di pagamento',
            "idVatCondition": 'Condizione IVA',
            "contacts": 'Contatti',
            "deliveryTime": 'Tempo di consegna standard [giorni]'
        },
        "supplier_contacts": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idSupplier": 'Fornitore',
            "name": 'Nome e cognome',
            "role": 'Ruolo',
            "phone": 'Telefono',
            "email": 'Email',
            "notes": 'Note'
        },
        "sales_agent": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome e cognome',
            "phone": 'Telefono',
            "email": 'Email',
            "percentage": 'Provvigione [%]'
        },
        "customer_payment_condition": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "notes": 'Note'
        },
        "supplier_payment_condition": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "notes": 'Note'
        },
        "vat_condition": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "percentage": 'Percentuale',
            "notes": 'Note'
        },
        "customer_offer": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Venditore',
            "idCustomer": 'Cliente',
            "idSalesAgent": 'Segnalatore',
            "internalCode": 'Codice interno',
            "riferimento": 'Riferimento',
            "file": 'Allegato',
            "floor": 'Piano edificio',
            "elevator": 'Ascensore',
            "parking": 'Parcheggio',
            "ladder_truck": 'Autoscala',
            "hoist_elevator": 'Montacarichi',
            "parquet": 'Parquet',
            "pedestrian_zone": 'Zona pedonale',
            "no_traffic_zone": 'ZTL',
            "building": 'Edificio',
            "zone": 'Zona',
            "deliveryDate": 'Data consegna prevista',
            "vat": 'IVA',
            "idPaymentCondition": 'Condizione di pagamento',
            "notes": 'Note',
            "rows": 'Righe',
            "status": 'Stato',
            "status.options.0_PENDING": 'In attesa',
            "status.options.5_PRESALE": 'Prevendita',
            "status.options.10_CONFIRMED": 'Accettato',
            "status.options.20_DECLINED": 'Rifiutato',
            "status.options.": 'In attesa',
            "discount": 'Sconto offerta [%]',
            "increase": 'Maggiorazione [%]',
            "transportCost": 'Spese di trasporto',
            "files": 'File',
            "deliveryAddress": 'Indirizzo di consegna',
            "totalPrice": 'Prezzo totale'
        },
        "customer_offer_files": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome file',
            "notes": 'Note',
            "file": 'File'
        },
        "customer_offer_row": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "code": 'Codice',
            "name": 'Articolo',
            "description": 'Descrizione',
            "idCategory": 'Categoria',
            "idSupplier": 'Fornitore',
            "quantity": 'Quantità',
            "price": 'Prezzo di vendita (iva ' + settings.iva_label + ')',
            "listPrice": 'Prezzo listino unitario',
            "discount": 'Sconto €',
            "discountPercentage": 'Sconto %',
            "notes": 'Note interne',
        },
        "customer_order": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Venditore',
            "idCustomer": 'Cliente',
            "idSalesAgent": 'Segnalatore',
            "internalCode": 'Codice interno',
            "riferimento": 'Riferimento',
            "deliveryDate": 'Data consegna prevista',
            "realDeliveryDate": 'Data consegna effettiva',
            "finishDeliveryDate": 'Data fine consegna effettiva',
            "deliveryNotes": 'Note di consegna',
            "idUserDelivery": 'Montatore',
            "file": 'Allegato',
            "floor": 'Piano edificio',
            "elevator": 'Ascensore',
            "parking": 'Parcheggio',
            "ladder_truck": 'Autoscala',
            "hoist_elevator": 'Montacarichi',
            "parquet": 'Parquet',
            "pedestrian_zone": 'Zona pedonale',
            "no_traffic_zone": 'ZTL',
            "building": 'Edificio',
            "zone": 'Zona',
            "vat": 'IVA',
            "idPaymentCondition": 'Condizione di pagamento',
            "notes": 'Note',
            "rows": 'Righe',
            "status": 'Stato',
            "status.options.0_PENDING": 'Da processare',
            "status.options.1_ORDERED": 'Ordinato a fornitore',
            "status.options.7_READY_FOR_DELIVERY": 'Pronto per la consegna',
            "status.options.10_COMPLETED": 'Consegnato',
            "status.options.20_CANCELED": 'Annullato',
            "discount": 'Sconto ordine [%]',
            "increase": 'Maggiorazione [%]',
            "transportCost": 'Spese di trasporto',
            "shippingDate": 'Data spedizone',
            "files": 'File',
            "invoiceable": 'Incassato',
            "supplier_orders": 'Ordini fornitore effettuati',
            "deliveryAddress": 'Indirizzo di consegna',
            "totalPrice": 'Prezzo totale'
        },
        "customer_order_files": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome file',
            "notes": 'Note',
            "file": 'File'
        },
        "customer_order_row": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "code": 'Codice',
            "name": 'Articolo',
            "description": 'Descrizione',
            "idCategory": 'Categoria',
            "idSupplier": 'Fornitore',
            "quantity": 'Quantità',
            "price": 'Prezzo di vendita (iva ' + settings.iva_label + ')',
            "listPrice": 'Prezzo listino unitario',
            "discount": 'Sconto €',
            "discountPercentage": 'Sconto %',
            "notes": 'Note interne',
        },
        "supplier_order": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idSupplier": 'Fornitore',
            "author": 'Venditore',
            "idCustomerOrder": 'Riferimento ordine cliente',
            "customerName": 'Nome cliente',
            "internalCode": 'Riferimento',
            "status": 'Stato',
            "status.options.0_PENDING": 'Da inviare',
            "status.options.2_SENT": 'Inviato',
            "status.options.5_PARTIAL": 'Ricevuto parzialmente',
            "status.options.10_COMPLETED": 'Ricevuto correttamente',
            "status.options.20_CANCELED": 'Annullato',
            "idPaymentCondition": 'Condizione di pagamento',
            "paymentNotes": 'Note di pagamento',
            "files": 'Conferma ordine',
            "confirmedDeliveryDate": 'Data consegna confermata',
            "notes": 'Note',
            "rows": 'Righe',
            "requestedDeliveryDate": 'Data consegna richiesta',
            "weekNumber": 'Settimana'
        },
        "supplier_order_files": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome file',
            "notes": 'Note',
            "file": 'File'
        },
        "supplier_order_row": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Articolo',
            "description": 'Descrizione',
            "idCategory": 'Categoria',
            "idSupplier": 'Fornitore',
            "quantity": 'Quantità',
            "notes": 'Note interne',
        },
        "activity": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "idCustomer": 'Cliente',
            "title": 'Titolo',
            "notes": 'Note',
            "type": 'Tipo attività',
            "type.options.0_PHONE": 'Telefonata',
            "type.options.10_EMAIL": 'Email',
            "type.options.20_MEET": 'Incontro',
            "status": 'Stato',
            "status.options.0_TODO": 'Da fare',
            "status.options.10_INPROGRESS": 'In corso',
            "status.options.20_DONE": 'Completata',
            "plannedDate": 'Data inizio attività',
            "plannedDateEnd": 'Data fine attività',
            "idCustomerOrder": 'Codice interno'
        },
        "delivery": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idUser": 'Montatore',
            "idCustomerOrder": 'Ordine cliente',
            "notes": 'Note',
            "status": 'Stato',
            "status.options.0_TODO": 'Da fare',
            "status.options.10_DONE": 'Completata',
            "plannedDate": 'Data consegna'
        },
        "category": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "idItemModelCategory": 'Categoria padre'
        },
        "transport_document_reason": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "notes": 'Note'
        },
        "log": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idItem": 'Id elemento',
            "idUser": 'Utente',
            "entity": 'Entità',
            "operation": 'Operazione',
            "operation.options.0_DELETE": 'Elimina',
            "operation.options.1_EDIT": 'Modifica',
            "operation.options.2_ADD": 'Aggiungi',
            "operation.options.3_LOGIN": 'Login',
            "operation.options.4_LOGOUT": 'Logout',
            "notes": 'Note',
        },
        "gyv_customer_offer": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'id Venditore',
            "idCustomer": 'id Cliente',
            "idSalesAgent": 'Segnalatore',
            "internalCode": 'Codice interno',
            "riferimento": 'Riferimento',
            "file": 'Allegato',
            "floor": 'Piano edificio',
            "elevator": 'Ascensore',
            "parking": 'Parcheggio',
            "ladder_truck": 'Autoscala',
            "hoist_elevator": 'Montacarichi',
            "parquet": 'Parquet',
            "pedestrian_zone": 'Zona pedonale',
            "no_traffic_zone": 'ZTL',
            "building": 'Edificio',
            "zone": 'Zona',
            "deliveryDate": 'Data consegna prevista',
            "vat": 'IVA',
            "idPaymentCondition": 'Condizione di pagamento',
            "notes": 'Note',
            "rows": 'Righe',
            "status": 'Stato',
            "status.options.0_PENDING": 'In attesa',
            "status.options.5_PRESALE": 'Prevendita',
            "status.options.10_CONFIRMED": 'Accettato',
            "status.options.20_DECLINED": 'Rifiutato',
            "status.options.": 'In attesa',
            "discount": 'Sconto offerta [%]',
            "increase": 'Maggiorazione [%]',
            "transportCost": 'Spese di trasporto',
            "files": 'File',
            "authorName": 'Venditore',
            "customer": 'Cliente',
            "salesAgent": 'Segnalatore',
            "deliveryAddress": 'Indirizzo di consegna',
            "totalPrice": 'Prezzo totale'
        },
        "gyv_customer_order": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'ID Venditore',
            "idCustomer": 'Cliente',
            "idSalesAgent": 'Segnalatore',
            "internalCode": 'Codice interno',
            "riferimento": 'Riferimento',
            "deliveryDate": 'Data consegna prevista',
            "realDeliveryDate": 'Data consegna effettiva',
            "finishDeliveryDate": 'Data fine consegna effettiva',
            "deliveryNotes": 'Note di consegna',
            "idUserDelivery": 'Montatore',
            "file": 'Allegato',
            "floor": 'Piano edificio',
            "elevator": 'Ascensore',
            "parking": 'Parcheggio',
            "ladder_truck": 'Autoscala',
            "hoist_elevator": 'Montacarichi',
            "parquet": 'Parquet',
            "pedestrian_zone": 'Zona pedonale',
            "no_traffic_zone": 'ZTL',
            "building": 'Edificio',
            "zone": 'Zona',
            "vat": 'IVA',
            "idPaymentCondition": 'Condizione di pagamento',
            "notes": 'Note',
            "rows": 'Righe',
            "status": 'Stato',
            "status.options.0_PENDING": 'Da processare',
            "status.options.1_ORDERED": 'Ordinato a fornitore',
            "status.options.7_READY_FOR_DELIVERY": 'Pronto per la consegna',
            "status.options.10_COMPLETED": 'Consegnato',
            "status.options.20_CANCELED": 'Annullato',
            "discount": 'Sconto ordine [%]',
            "increase": 'Maggiorazione [%]',
            "transportCost": 'Spese di trasporto',
            "shippingDate": 'Data spedizone',
            "files": 'File',
            "invoiceable": 'Incassato',
            "supplier_orders": 'Ordini fornitore effettuati',
            "authorName": 'Venditore',
            "customer": 'Cliente',
            "salesAgent": 'Segnalatore',
            "deliveryAddress": 'Indirizzo di consegna',
            "totalPrice": 'Prezzo totale'
        },
        "gyv_supplier_order": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idSupplier": 'Fornitore',
            "author": 'ID Venditore',
            "idCustomerOrder": 'Riferimento ordine cliente',
            "customerName": 'Nome cliente',
            "internalCode": 'Riferimento',
            "status": 'Stato',
            "status.options.0_PENDING": 'Da inviare',
            "status.options.2_SENT": 'Inviato',
            "status.options.5_PARTIAL": 'Ricevuto parzialmente',
            "status.options.10_COMPLETED": 'Ricevuto correttamente',
            "status.options.20_CANCELED": 'Annullato',
            "idPaymentCondition": 'Condizione di pagamento',
            "paymentNotes": 'Note di pagamento',
            "files": 'Conferma ordine',
            "confirmedDeliveryDate": 'Data consegna confermata',
            "notes": 'Note',
            "rows": 'Righe',
            "requestedDeliveryDate": 'Data consegna richiesta',
            "weekNumber": 'Settimana',
            "authorName": 'Venditore',
            "supplier": 'Fornitore'
        },
        "gyv_customer_order_rows_open": {
            "id": 'id',
            "date": 'Data creazione',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Articolo',
            "description": 'Descrizione',
            "category": 'Categoria',
            "supplier": 'Fornitore',
            "quantity": 'Quantità',
            "listPrice": 'Prezzo',
            "customer": 'Cliente',
            "dateOrder": 'Data ordine',
            "idOrder": 'ID ordine',
            "internalCode": 'Codice interno',
            "status": 'Stato ordine',
            "status.options.0_PENDING": 'Da processare',
            "status.options.1_ORDERED": 'Ordinato a fornitore',
            "status.options.7_READY_FOR_DELIVERY": 'Pronto per la consegna',
            "status.options.10_COMPLETED": 'Consegnato',
            "status.options.20_CANCELED": 'Annullato',
            "author": 'Author'
        },
        "notification": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "notificationAll": 'Notifiche attive',
            "notificationOrder": 'Notifiche ordini',
            "notificationOffer": 'Notifiche preventivi',
            "idUser": 'Utente'
        },
        "customer_order_payment": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idCustomerOrder": 'Ordine',
            "idCustomer": 'Cliente',
            "paymentDate": 'Data pagamento',
            "totalPayment": 'Importo versato',
            "notes": 'Note'
        },
    },
    
};