var m2 = [
    {
        "type": 'item',
        "href": '/',
        "icon": 'home',
        "entity": '',
        "label": 'Dashboard'
    },
    {
        "type": 'divider',
        "href": '#',
        "icon": '',
        "entity": ''
    },
    {
        "type": 'item',
        "href": '/entity/customer',
        "icon": 'users',
        "entity": 'customer',
        "label": 'Clienti'
    },
    {
        "type": 'item',
        "href": '/page/CustomerMap',
        "icon": 'map',
        "entity": 'customer',
        "label": 'Mappa clienti'
    },
    {
        "type": 'item',
        "href": '/entity/activity',
        "icon": 'list-check',
        "entity": 'activity',
        "label": 'Attività'
    },
    {
        "type": 'item',
        "href": '/page/CalendarActivities',
        "icon": 'calendar',
        "entity": '',
        "label": 'Calendario'
    },
    {
        "type": 'divider',
        "href": '#',
        "icon": '',
        "entity": ''
    },
    {
        "type": 'item',
        "href": '/page/Statistics',
        "icon": 'chart-line',
        "entity": '',
        "label": 'Statistiche'
    },
    {
        "type": 'divider',
        "href": '#',
        "icon": '',
        "entity": ''
    },
    {
        "type": 'item',
        "href": '/entity/gyv_customer_offer',
        "icon": 'receipt',
        "entity": 'customer_offer',
        "label": 'Preventivi'
    },
    {
        "type": 'divider',
        "href": '#',
        "icon": '',
        "entity": ''
    },
    {
        "type": 'item',
        "href": '/page/DashboardCustomerOrder',
        "icon": 'gauge',
        "entity": 'customer_order',
        "label": 'Ordini cliente da gestire'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_customer_order',
        "icon": 'cart-shopping',
        "entity": 'customer_order',
        "label": 'Elenco ordini cliente'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_customer_order_rows_open',
        "icon": 'list',
        "entity": 'gyv_customer_order_rows_open',
        "label": 'Righe ordini clienti'
    },
    {
        "type": 'item',
        "href": '/entity/customer_order_payment',
        "icon": 'clock-rotate-left',
        "entity": 'customer_order_payment',
        "label": 'Storico pagamenti'
    },
    {
        "type": 'divider',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Ordini fornitore'
    },
    {
        "type": 'item',
        "href": '/page/DashboardSupplierOrder',
        "icon": 'gauge',
        "entity": 'supplier_order',
        "label": 'Ordini fornitore da gestire'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_supplier_order',
        "icon": 'cart-shopping',
        "entity": 'supplier_order',
        "label": 'Elenco ordini fornitore'
    },
    {
        "type": 'divider',
        "href": '#',
        "icon": '',
        "entity": ''
    },
    {
        "type": 'item',
        "href": '/page/DashboardShipping',
        "icon": 'truck',
        "entity": 'customer_order',
        "label": 'Ordini da consegnare'
    },
    {
        "type": 'item',
        "href": '/page/CalendarDelivery',
        "icon": 'calendar',
        "entity": 'customer_order',
        "label": 'Calendario consegne'
    },
    {
        "type": 'item',
        "href": '/page/OrderDeliveryMap',
        "icon": 'map',
        "entity": 'customer_order',
        "label": 'Mappa consegne'
    },
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Impostazioni'
    },
    {
        "type": 'item',
        "href": '/settings',
        "icon": 'user-gear',
        "entity": '',
        "label": 'Profilo'
    },
    {
        "type": 'item',
        "href": '/logout',
        "icon": 'arrow-right-from-bracket',
        "entity": '',
        "label": 'Logout'
    },
    {
        "type": 'folder',
        "href": '#',
        "icon": 'list',
        "entity": '',
        "label": 'Anagrafiche',
        "items": [
            {
                "href": '/entity/user',
                "label": 'Utenti',
                "entity": 'user'
            },
            {
                "href": '/entity/supplier',
                "label": 'Fornitori',
                "entity": 'supplier'
            }
        ]
    },
    {
        "type": 'folder',
        "href": '#',
        "icon": 'cog',
        "entity": '',
        "label": 'Impostazioni',
        "items": [
        
            {
                "href": '/entity/user_group',
                "label": 'Gruppi',
                "entity": 'user_group'
            },
            {
                "href": '/page/GoogleCalendar',
                "label": 'Sincronizza Google Calendar',
                "entity": 'activity'
            },
            {
                "href": '/entity/notification',
                "label": 'Notifiche',
                "entity": 'notification'
            },
            {
                "href": '/entity/payment_condition',
                "label": 'Condizioni pagamento',
                "entity": 'payment_condition'
            },
            {
                "href": '/entity/vat_condition',
                "label": 'Condizioni IVA',
                "entity": 'vat_condition'
            },
            {
                "href": '/entity/category',
                "label": 'Categorie',
                "entity": 'category'
            },
            {
                "href": '/entity/supplier_payment_condition',
                "label": 'Condizioni di pagamento fornitori',
                "entity": 'supplier_payment_condition'
            },
            {
                "href": '/entity/customer_payment_condition',
                "label": 'Condizioni di pagamento clienti',
                "entity": 'customer_payment_condition'
            }
        ]
    },
    {
        "type": 'item',
        "href": '/entity/user_entity_saved_filters',
        "icon": 'save',
        "entity": 'user_entity_saved_filters',
        "label": 'Filtri salvati'
    },
    {
        "type": 'item',
        "href": '/entity/user_entity_preferences',
        "icon": 'cogs',
        "entity": 'user_entity_preferences',
        "label": 'Preferenze entità'
    },
    {
        "type": 'item',
        "href": '/entity/log',
        "icon": 'terminal',
        "entity": 'log',
        "label": 'Logs'
    }

]

var user = localStorage.getItem('userInfo');
if (user) {
    var userInfo = JSON.parse(user);
    console.log(userInfo);
    if (userInfo.level === 1) {
        m2 = [ {
            "type": 'item',
            "href": '/',
            "icon": 'home',
            "entity": '',
            "label": 'Dashboard'
        },
        {
            "type": 'divider',
            "href": '#',
            "icon": '',
            "entity": ''
        },
        {
            "type": 'item',
            "href": '/entity/customer',
            "icon": 'users',
            "entity": 'customer',
            "label": 'Clienti'
        },
        {
            "type": 'item',
            "href": '/page/CustomerMap',
            "icon": 'map',
            "entity": 'customer',
            "label": 'Mappa clienti'
        },
        {
            "type": 'item',
            "href": '/entity/activity',
            "icon": 'list-check',
            "entity": 'activity',
            "label": 'Attività'
        },
        {
            "type": 'item',
            "href": '/page/CalendarActivities',
            "icon": 'calendar',
            "entity": '',
            "label": 'Calendario'
        },
        {
            "type": 'divider',
            "href": '#',
            "icon": '',
            "entity": ''
        },
        {
            "type": 'item',
            "href": '/page/Statistics',
            "icon": 'chart-line',
            "entity": '',
            "label": 'Statistiche'
        },
        {
            "type": 'divider',
            "href": '#',
            "icon": '',
            "entity": ''
        },
        {
            "type": 'item',
            "href": '/entity/gyv_customer_offer',
            "icon": 'receipt',
            "entity": 'customer_offer',
            "label": 'Preventivi'
        },
        {
            "type": 'divider',
            "href": '#',
            "icon": '',
            "entity": ''
        },
        {
            "type": 'item',
            "href": '/page/DashboardCustomerOrder',
            "icon": 'gauge',
            "entity": 'customer_order',
            "label": 'Ordini cliente da gestire'
        },
        {
            "type": 'item',
            "href": '/entity/gyv_customer_order',
            "icon": 'cart-shopping',
            "entity": 'customer_order',
            "label": 'Elenco ordini cliente'
        },
        {
            "type": 'item',
            "href": '/entity/gyv_customer_order_rows_open',
            "icon": 'list',
            "entity": 'gyv_customer_order_rows_open',
            "label": 'Righe ordini clienti'
        },
        {
            "type": 'item',
            "href": '/entity/customer_order_payment',
            "icon": 'clock-rotate-left',
            "entity": 'customer_order_payment',
            "label": 'Storico pagamenti'
        },
        {
            "type": 'divider',
            "href": '#',
            "icon": '',
            "entity": '',
            "label": 'Ordini fornitore'
        },
        {
            "type": 'item',
            "href": '/page/DashboardSupplierOrder',
            "icon": 'gauge',
            "entity": 'supplier_order',
            "label": 'Ordini fornitore da gestire'
        },
        {
            "type": 'item',
            "href": '/entity/gyv_supplier_order',
            "icon": 'cart-shopping',
            "entity": 'supplier_order',
            "label": 'Elenco ordini fornitore'
        },
        {
            "type": 'divider',
            "href": '#',
            "icon": '',
            "entity": ''
        },
        {
            "type": 'item',
            "href": '/page/DashboardShipping',
            "icon": 'truck',
            "entity": 'customer_order',
            "label": 'Ordini da consegnare'
        },
        {
            "type": 'item',
            "href": '/page/CalendarDelivery',
            "icon": 'calendar',
            "entity": 'customer_order',
            "label": 'Calendario consegne'
        },
        {
            "type": 'item',
            "href": '/page/OrderDeliveryMap',
            "icon": 'map',
            "entity": 'customer_order',
            "label": 'Mappa consegne'
        },
        {
            "type": 'header',
            "href": '#',
            "icon": '',
            "entity": '',
            "label": 'Impostazioni'
        },
        {
            "type": 'item',
            "href": '/settings',
            "icon": 'user-gear',
            "entity": '',
            "label": 'Profilo'
        },
        {
            "type": 'item',
            "href": '/logout',
            "icon": 'arrow-right-from-bracket',
            "entity": '',
            "label": 'Logout'
        },
        {
            "type": 'folder',
            "href": '#',
            "icon": 'list',
            "entity": '',
            "label": 'Anagrafiche',
            "items": [
                {
                    "href": '/entity/user',
                    "label": 'Utenti',
                    "entity": 'user'
                },
                {
                    "href": '/entity/supplier',
                    "label": 'Fornitori',
                    "entity": 'supplier'
                }
            ]
        },
        {
            "type": 'folder',
            "href": '#',
            "icon": 'cog',
            "entity": '',
            "label": 'Impostazioni',
            "items": [
            
                {
                    "href": '/entity/user_group',
                    "label": 'Gruppi',
                    "entity": 'user_group'
                },
                {
                    "href": '/page/GoogleCalendar',
                    "label": 'Sincronizza Google Calendar',
                    "entity": 'activity'
                },
                {
                    "href": '/entity/notification',
                    "label": 'Notifiche',
                    "entity": 'notification'
                },
                {
                    "href": '/entity/payment_condition',
                    "label": 'Condizioni pagamento',
                    "entity": 'payment_condition'
                },
                {
                    "href": '/entity/vat_condition',
                    "label": 'Condizioni IVA',
                    "entity": 'vat_condition'
                },
                {
                    "href": '/entity/category',
                    "label": 'Categorie',
                    "entity": 'category'
                },
                {
                    "href": '/entity/supplier_payment_condition',
                    "label": 'Condizioni di pagamento fornitori',
                    "entity": 'supplier_payment_condition'
                },
                {
                    "href": '/entity/customer_payment_condition',
                    "label": 'Condizioni di pagamento clienti',
                    "entity": 'customer_payment_condition'
                }
            ]
        },
        {
            "type": 'item',
            "href": '/entity/user_entity_saved_filters',
            "icon": 'save',
            "entity": 'user_entity_saved_filters',
            "label": 'Filtri salvati'
        },
        {
            "type": 'item',
            "href": '/entity/user_entity_preferences',
            "icon": 'cogs',
            "entity": 'user_entity_preferences',
            "label": 'Preferenze entità'
        },
        {
            "type": 'item',
            "href": '/entity/log',
            "icon": 'terminal',
            "entity": 'log',
            "label": 'Logs'
        }]
    }
    else if (userInfo.level === 2) {
        m2 = [ {
            "type": 'item',
            "href": '/page/DashboardShipping',
            "icon": 'truck',
            "entity": 'customer_order',
            "label": 'Ordini da consegnare'
        },
        {
            "type": 'item',
            "href": '/page/CalendarDelivery',
            "icon": 'calendar',
            "entity": 'customer_order',
            "label": 'Calendario consegne'
        },
        {
            "type": 'item',
            "href": '/page/OrderDeliveryMap',
            "icon": 'map',
            "entity": 'customer_order',
            "label": 'Mappa consegne'
        },
        {
            "type": 'header',
            "href": '#',
            "icon": '',
            "entity": '',
            "label": 'Impostazioni'
        },
        {
            "type": 'item',
            "href": '/settings',
            "icon": 'user-gear',
            "entity": '',
            "label": 'Profilo'
        },
        {
            "type": 'item',
            "href": '/entity/user_entity_saved_filters',
            "icon": 'save',
            "entity": 'user_entity_saved_filters',
            "label": 'Filtri salvati'
        },
        {
            "type": 'item',
            "href": '/entity/user_entity_preferences',
            "icon": 'cogs',
            "entity": 'user_entity_preferences',
            "label": 'Preferenze entità'
        },
        {
            "type": 'item',
            "href": '/logout',
            "icon": 'arrow-right-from-bracket',
            "entity": '',
            "label": 'Logout'
        }]
    }
    else if(userInfo.level === 3){
        m2 = [
            {
                "type": 'item',
                "href": '/',
                "icon": 'home',
                "entity": '',
                "label": 'Dashboard'
            },
            {
                "type": 'divider',
                "href": '#',
                "icon": '',
                "entity": ''
            },
            {
                "type": 'item',
                "href": '/entity/customer',
                "icon": 'users',
                "entity": 'customer',
                "label": 'Clienti'
            },
            {
                "type": 'item',
                "href": '/page/CustomerMap',
                "icon": 'map',
                "entity": 'customer',
                "label": 'Mappa clienti'
            },
            {
                "type": 'item',
                "href": '/entity/activity',
                "icon": 'list-check',
                "entity": 'activity',
                "label": 'Attività'
            },
            {
                "type": 'item',
                "href": '/page/CalendarActivities',
                "icon": 'calendar',
                "entity": '',
                "label": 'Calendario'
            },
            {
                "type": 'divider',
                "href": '#',
                "icon": '',
                "entity": '',
                "label": 'Ordini cliente'
            },
            {
                "type": 'item',
                "href": '/entity/gyv_customer_offer',
                "icon": 'receipt',
                "entity": 'customer_offer',
                "label": 'Preventivi'
            },
            {
                "type": 'divider',
                "href": '#',
                "icon": '',
                "entity": ''
            },
            {
                "type": 'item',
                "href": '/page/DashboardCustomerOrder',
                "icon": 'gauge',
                "entity": 'customer_order',
                "label": 'Ordini cliente da gestire'
            },
            {
                "type": 'item',
                "href": '/entity/gyv_customer_order',
                "icon": 'cart-shopping',
                "entity": 'customer_order',
                "label": 'Elenco ordini cliente'
            },
            {
                "type": 'item',
                "href": '/entity/gyv_customer_order_rows_open',
                "icon": 'list',
                "entity": 'gyv_customer_order_rows_open',
                "label": 'Righe ordini clienti'
            },
            {
                "type": 'item',
                "href": '/entity/customer_order_payment',
                "icon": 'clock-rotate-left',
                "entity": 'customer_order_payment',
                "label": 'Storico pagamenti'
            },
            {
                "type": 'divider',
                "href": '#',
                "icon": '',
                "entity": '',
                "label": 'Ordini fornitore'
            },
            {
                "type": 'item',
                "href": '/page/DashboardSupplierOrder',
                "icon": 'gauge',
                "entity": 'supplier_order',
                "label": 'Ordini fornitore da gestire'
            },
            {
                "type": 'item',
                "href": '/entity/gyv_supplier_order',
                "icon": 'cart-shopping',
                "entity": 'supplier_order',
                "label": 'Elenco ordini fornitore'
            },
            {
                "type": 'divider',
                "href": '#',
                "icon": '',
                "entity": ''
            },
            {
                "type": 'item',
                "href": '/page/DashboardShipping',
                "icon": 'truck',
                "entity": 'customer_order',
                "label": 'Ordini da consegnare'
            },
            {
                "type": 'item',
                "href": '/page/CalendarDelivery',
                "icon": 'calendar',
                "entity": 'customer_order',
                "label": 'Calendario consegne'
            },
            {
                "type": 'item',
                "href": '/page/OrderDeliveryMap',
                "icon": 'map',
                "entity": 'customer_order',
                "label": 'Mappa consegne'
            },
            {
                "type": 'header',
                "href": '#',
                "icon": '',
                "entity": '',
                "label": 'Impostazioni'
            },
            {
                "type": 'item',
                "href": '/settings',
                "icon": 'user-gear',
                "entity": '',
                "label": 'Profilo'
            },
            {
                "type": 'item',
                "href": '/logout',
                "icon": 'arrow-right-from-bracket',
                "entity": '',
                "label": 'Logout'
            },
            {
                "type": 'folder',
                "href": '#',
                "icon": 'list',
                "entity": '',
                "label": 'Anagrafiche',
                "items": [
                    {
                        "href": '/entity/supplier',
                        "label": 'Fornitori',
                        "entity": 'supplier'
                    }
                ]
            },
            {
                "type": 'folder',
                "href": '#',
                "icon": 'cog',
                "entity": '',
                "label": 'Impostazioni',
                "items": [
                
                    {
                        "href": '/entity/user_group',
                        "label": 'Gruppi',
                        "entity": 'user_group'
                    },
                    {
                        "href": '/page/GoogleCalendar',
                        "label": 'Sincronizza Google Calendar',
                        "entity": 'activity'
                    },
                    {
                        "href": '/entity/notification',
                        "label": 'Notifiche',
                        "entity": 'notification'
                    },
                    {
                        "href": '/entity/payment_condition',
                        "label": 'Condizioni pagamento',
                        "entity": 'payment_condition'
                    },
                    {
                        "href": '/entity/vat_condition',
                        "label": 'Condizioni IVA',
                        "entity": 'vat_condition'
                    },
                    {
                        "href": '/entity/category',
                        "label": 'Categorie',
                        "entity": 'category'
                    },
                    {
                        "href": '/entity/supplier_payment_condition',
                        "label": 'Condizioni di pagamento fornitori',
                        "entity": 'supplier_payment_condition'
                    },
                    {
                        "href": '/entity/customer_payment_condition',
                        "label": 'Condizioni di pagamento clienti',
                        "entity": 'customer_payment_condition'
                    }
                ]
            },
            {
                "type": 'item',
                "href": '/entity/user_entity_saved_filters',
                "icon": 'save',
                "entity": 'user_entity_saved_filters',
                "label": 'Filtri salvati'
            },
            {
                "type": 'item',
                "href": '/entity/user_entity_preferences',
                "icon": 'cogs',
                "entity": 'user_entity_preferences',
                "label": 'Preferenze entità'
            },
            {
                "type": 'item',
                "href": '/entity/log',
                "icon": 'terminal',
                "entity": 'log',
                "label": 'Logs'
            }
        
        ]
    }
}








export const menu = m2